<template>
    <div>

        <h2>Please sign in to PhuAdmin</h2>
        <form class="form-horizontal" @submit.prevent="dosubmit">
            <div class="form-group row">
                <label for="username" class="col-sm-2 control-label">Username</label>

                <div class="col-sm-10">
                    <input class="form-control" v-model.trim="user" id="username" placeholder="" @keypress="keypress">
                </div>
            </div>
            <div class="form-group row">
                <label for="password" class="col-sm-2 control-label">Password</label>

                <div class="col-sm-10">
                    <input type="password" class="form-control" v-model.trim="pass" id="password" placeholder="" @keypress="keypress">
                </div>
            </div>
            <div class="row">
                <label for="error" class="col-sm-2 control-label"></label>

                <div class="col-sm-10" :class="{'has-error':error!=''}">
                    <span class="help-block red-text basic-block">{{error}}</span>
                </div>
            </div>
            <div class="form-group row">
                <label for="remember" class="col-sm-2 control-label"></label>

                <div class="col-sm-10">
                    <div class="has-success ng-scope"><div class="checkbox"><label class="custom-checkbox"><input type="checkbox" id="checkboxSuccess" v-model="remember"> <span>Remember me?</span></label></div></div>
                </div>
            </div>

            <div class="form-group">
                <div class="text-center">
                    <button type="submit" class="btn btn-primary btn-auth">Sign in</button>
                </div>
            </div>
        </form>
        
    </div>
</template>
<script>
    
    import $ from 'jquery'
    import { mapState,  mapActions } from 'vuex'
    export default {
        name: `Login`,
        props: ['user','pass','remember'],
        data: () => {
            return {
                error: ''
            }
        },        
        computed: mapState({
            modalCallback: state => state.view.Modal.triggerConfirm, //when user click yes on modal confirm, then watch it
        }),
        created() {
            // check user loggedin
            const sex = this.$store.state.session.token;
            if (sex != null && typeof (sex) != "undefined" && sex != "") {
                this.$router.push('/');
            }
        },
        mounted() {
            //force layout no margin-left if is login page     
            this.setLayoutClass('auth-main');            
            $('.auth-block').css('margin-left','');
        },
        methods: {
            ...mapActions({
                'login': 'session/login',
                'setSession': 'session/setSession',
                'setLayoutClass': 'view/setLayoutClass',
                'loading': 'view/setLoading',
                'showSidebar': 'view/showSidebar',
                'showModal': 'view/showModal',
                'setCheckLogin': 'view/setCheckLogin',
                }),
            
            dosubmit: async function (e,forceLogout) {
                forceLogout = forceLogout || false;
                //same as: 
                //const rs = await this.$store.dispatch('session/login', this.user, this.pass);
                this.loading(true);
                const rs = await this.login({ User: this.user, Pass: this.pass, forceLogout: forceLogout, remember: this.remember });
                this.loading(false);                
                this.error = rs.Error;
                if (rs.Error != "") {
                    if (rs.Error.includes("User currently login in other devices")) {
                        //show modal with callback name = loginView to check it whenever modal confirm triggerConfirm
                        this.showModal({
                            isShow: true, title: 'Warning', content: rs.Error + "<br /> Force logout user?", type: 'error', callback: () => {
                                this.dosubmit(this, true);
                            }, isConfirm: true });
                    }
                } else {
                    //set session and redirect to dashboard 
                    
                    this.setSession(rs.Data);
                    this.setCheckLogin(true);
                    this.$router.push('/');
                }
            },
            keypress: function (e) {                
                this.error = "";
                if (e.code == 'Enter' && e.target.getAttribute("id") == "username") {                    
                    e.preventDefault();
                    document.getElementById("password").focus();
                }
            },
        },


    };</script>
